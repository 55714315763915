import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Sidebar from "../components/core/Dashboard/Sidebar";

function Dashboard() {
  const { loading: profileLoading } = useSelector((state) => state.profile);
  const { loading: authLoading } = useSelector((state) => state.auth);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isSidebarOpen]);

  if (profileLoading || authLoading) {
    return (
      <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="relative overflow-hidden flex bg-richblack-900 min-h-[calc(100vh-3.5rem)]">
      {/* Sidebar for larger screens */}
      <div className="hidden lg:block">
        <Sidebar onLinkClick={toggleSidebar} />
      </div>

      {/* Sidebar icon for mobile screens */}
      {!isSidebarOpen && (
        <div className="lg:hidden fixed top-12  inline-flex   p-4 text-white z-0"> 
          <button onClick={toggleSidebar} className="text-2xl   mt-2"> 
            <AiOutlineMenu />
          </button>
          <span onClick={toggleSidebar}  className=" cursor-pointer font-semibold ml-2 text-xl mt-2 " >Dashboard</span>  
        </div>
      )}

      {/* Mobile Sidebar */}
      <div
        className={`lg:hidden fixed top-13 left-0 h-full bg-richblack-800 text-white z-0 transition-transform transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        style={{ transition: 'transform 0.3s ease-in-out' }}
      >
        <div className="flex justify-between items-center p-4">
          <span className="text-xl ml-4 font-semibold">Dashboard</span>
          <button onClick={toggleSidebar} className="text-2xl">
            <AiOutlineClose />
          </button>
        </div>
        <Sidebar onLinkClick={toggleSidebar} />
      </div>

      {/* Content area */}
      <div
        className={`h-[calc(100vh-3.5rem)] flex-1 overflow-auto  w-full mt-12 lg:mt-0 ${isSidebarOpen ? "overflow-auto" : "overflow-auto"
          }`}
      >
        <div className="mx-auto w-11/12 max-w-[1000px] py-14">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
