import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';

const ImageSlider = () => {
    const images = [
        "https://i.ibb.co/GtbCMmy/interface.jpg",
        "https://i.ibb.co/xKZcMpW/e-sat.jpg",
        "https://i.ibb.co/JtTjy5r/e-sat-2023.jpg"
        
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [currentIndex, images.length]);

    const prevSlide = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        } else {
            setCurrentIndex(images.length - 1);
        }
    };

    const nextSlide = () => {
        if (currentIndex < images.length - 1) {
            setCurrentIndex(currentIndex + 1);
        } else {
            setCurrentIndex(0);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center  bg-gray-100">
            <h1 className="text-5xl text-richblack-700 text-center  mt-4 items-center justify-center mb-4">
                ACADAMIC <span className="text-black bg-yellow-25 px-2">ACHIVER</span>
            </h1>
    
            <h2 className="text-2xl text-blue-300 mb-8">Explore Our Amazing Collection</h2>
            
            <div className="relative flex items-center w-11/12 rounded-lg p-4 mt-8">
                <div className="w-fit max-h-full overflow-hidden rounded-lg">
                    <div className="flex transition-transform duration-1000" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                        {images.map((src, index) => (
                            <img key={index} src={src} alt={`Slide ${index}`} className="min-w-full h-full object-cover" />
                        ))}
                    </div>
                </div>
            </div>
            
        </div>
    );
    
   
    
};

export default ImageSlider;
