import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGraduate, faChalkboardTeacher, faBook, faTrophy, faClipboardCheck } from "@fortawesome/free-solid-svg-icons";

const Stats = [
  { count: "Mock Test", label: "Students get more than 5 tests in a week", icon: faClipboardCheck },
  { count: "Live Class", label: "Highly experienced and educated teachers are teaching our students", icon: faChalkboardTeacher },
  { count: "Notes", label: "Courses in 3+ languages with free notes and quizzes", icon: faBook },
  { count: "Courses", label: "We appreciate our students for every win", icon: faTrophy },
  { count: "Exam Ready", label: "We make our students 100% exam ready", icon: faUserGraduate },
];

const Features = () => {
  return (
    <div className="stats-container">
      <div className="stats-grid">
        {Stats.map((data, index) => {
          return (
            <div className="stats-card" key={index}>
              <FontAwesomeIcon icon={data.icon} className="icon" />
              <h1 className="count">{data.count}</h1>
              <h2 className="label">{data.label}</h2>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Features;
