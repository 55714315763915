import { FcGoogle } from "react-icons/fc"
import { useSelector } from "react-redux"

import frameImg from "../../../assets/Images/frame.png"
import LoginForm from "./LoginForm"
import SignupForm from "./SignupForm"
import ContactDetails from "../ContactUsPage/ContactDetails"

function Template({ title, description1, description2, image, formType }) {
  const { loading } = useSelector((state) => state.auth)

  return (
    <div className="grid min-h-[calc(100vh-20.5rem)] place-items-center">
      {loading ? (
        <div className="spinner"></div>
      ) : (
        <div className="mx-auto flex w-11/12 max-w-maxContent flex-col-reverse justify-center gap-y-12 py-12 md:flex-row md:gap-y-0 md:gap-x-12">
          {/* <div className="lg:w-[50%]">
            <ContactDetails />
          </div> */}



          <div className="mx-auto w-11/12 max-w-[550px] md:mx-0">
            <h1 className="text-[1.875rem] font-semibold leading-[2.375rem] text-blue-400">
              {title}
            </h1>
            <p className="mt-4 text-[1.125rem] leading-[1.625rem]">
              <span className="text-richblack-100">{description1}</span>{" "}
              <span className="font-edu-sa font-bold italic text-blue-100">
                {description2}
              </span>
            </p>
            <br></br>
            {formType === "signup" ? <SignupForm /> : <LoginForm />}
          </div>




          <div className="relative mx-auto w-11/12 mt-10  max-w-[550px] md:mx-0">
            <img
              src={image}
              alt="Pattern"
              width={758}
              height={704}
              loading="lazy"
            />
            {/* <img
              // src={image}
              // alt="Students"
              width={658}
              height={604}
              loading="lazy"
              className="absolute -top-4 right-4 z-10"
            /> */}
          </div>

        </div>
      )}
    </div>
  )
}

export default Template
