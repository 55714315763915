import React from 'react'
import HighlightText from './HighlightText'
import CTAButton from "../../../components/core/HomePage/Button";
import Know_your_progress from "../../../assets/Images/Know_your_progress.png";
// import Compare_with_others from "../../../assets/Images/Compare_with_others.jpg";
// import Plan_your_lessons from "../../../assets/Images/Plan_your_lessons.jpg";

const Plan_your_lessons = "https://i.ibb.co/x5tL8mF/fonder-2.jpg";
const Compare_with_others ="https://i.ibb.co/ZdhpHqj/Compare-with-others.jpg";


const LearningLanguageSection = () => {
  return (
    <div>
      <div className="text-4xl font-semibold text-blue-400 text-center my-10">
        Meet the Founder and Instructor of
        <HighlightText text={"Excel Classes"} />
        <div className="text-center text-richblack-700 font-medium lg:w-[75%] mx-auto leading-6 mb-10 text-base mt-3">
          Our founder and instructor bring a wealth of expertise and dedication to your learning journey. With a passion for teaching and a commitment to student success, they provide personalized guidance and support to help you excel in Excel.            </div>
        <div className="flex flex-col lg:flex-row items-center justify-center mt-8 lg:mt-0 space-y-8 lg:space-y-0 lg:space-x-8">
          <img
            src={Compare_with_others}
            alt="Comparison"
            className="object-contain h-auto max-h-[500px] w-full lg:w-[498px]  transition-transform duration-300 hover:scale-105"
          />
          <img
            src={Plan_your_lessons}
            alt="Lesson Planning"
            className="object-contain h-auto max-h-[500px] w-full lg:w-[498px]  transition-transform duration-300 hover:scale-105"
          />
        </div>

      </div>

      <div className="w-fit mx-auto lg:mb-20 mb-8 mt-8 -mt-5">
        <CTAButton active={true} linkto={"/signup"}>
          <div className="">Learn More</div>
        </CTAButton>
      </div>
    </div>
  )
}

export default LearningLanguageSection