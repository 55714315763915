export const HomePageExplore = [
    {
        tag: 'Free',
        courses : [
            {
                heading : "Mathematics",
                description : "This course covers fundamental concepts in mathematics including algebra, geometry, and calculus, tailored for class 10 to 12 students.",
                level : '10th to 12th',
                lessionNumber : 6
            },
            {
                heading : "Science",
                description : "Explore core principles of physics, chemistry, and biology with interactive lessons designed for high school students.",
                level : '10th to 12th',
                lessionNumber : 6
            },
            {
                heading : "English",
                description : "Improve language skills with comprehensive courses covering grammar, comprehension, and writing for school students.",
                level : '10th to 12th',
                lessionNumber : 6
            },
      
        ]
    },
    // {
    //     tag: 'New to coding',
    //     courses : [
    //         {
    //             heading : "Physics",
    //             description : "Master the basics of physics with clear explanations and practical examples suitable for beginners in class 10 to 12.",
    //             level : 'Beginner',
    //             lessionNumber : 6
    //         },
    //         {
    //             heading : "Chemistry",
    //             description : "Learn essential concepts of chemistry through engaging lessons and hands-on experiments for high school students.",
    //             level : 'Beginner',
    //             lessionNumber : 6
    //         },
    //         {
    //             heading : "Biology",
    //             description : "Explore the fascinating world of biology with in-depth coverage of life sciences topics for students in class 10 to 12.",
    //             level : 'Beginner',
    //             lessionNumber : 6
    //         },
    //     ]
    // },
    // {
    //     tag: 'Most popular',
    //     courses : [
    //         {
    //             heading : "Mathematics II",
    //             description : "This course covers advanced topics in mathematics including statistics, trigonometry, and probability for high school students.",
    //             level : 'Intermediate',
    //             lessionNumber : 6
    //         },
    //         {
    //             heading : "Science II",
    //             description : "Delve deeper into physics, chemistry, and biology with comprehensive courses designed to prepare students for exams.",
    //             level : 'Intermediate',
    //             lessionNumber : 6
    //         },
    //         {
    //             heading : "Computer Science",
    //             description : "Learn programming fundamentals and computer science principles with practical exercises and real-world applications.",
    //             level : 'Intermediate',
    //             lessionNumber : 6
    //         },
    //     ]
    // },
    // {
    //     tag: 'Skills paths',
    //     courses : [
    //         {
    //             heading : "Mathematics III",
    //             description : "Advanced course focusing on calculus, vectors, and advanced algebraic concepts for students aiming for competitive exams.",
    //             level : 'Advanced',
    //             lessionNumber : 6
    //         },
    //         {
    //             heading : "Science III",
    //             description : "Comprehensive coverage of advanced physics, chemistry, and biology topics essential for entrance exams preparation.",
    //             level : 'Advanced',
    //             lessionNumber : 6
    //         },
    //         {
    //             heading : "Programming Fundamentals",
    //             description : "Build a solid foundation in programming languages like Python, Java, and C++ with practical coding exercises.",
    //             level : 'Intermediate',
    //             lessionNumber : 6
    //         },
    //     ]
    // },
    // {
    //     tag: 'Career paths',
    //     courses : [
    //         {
    //             heading : "Mathematics IV",
    //             description : "Specialized course focusing on applied mathematics and problem-solving strategies for competitive exams and future careers.",
    //             level : 'Advanced',
    //             lessionNumber : 6
    //         },
    //         {
    //             heading : "Science IV",
    //             description : "Advanced studies in specialized areas of physics, chemistry, and biology for students pursuing careers in science.",
    //             level : 'Advanced',
    //             lessionNumber : 6
    //         },
    //         {
    //             heading : "Programming Languages",
    //             description : "Master advanced programming languages and frameworks used in software development for career advancement.",
    //             level : 'Advanced',
    //             lessionNumber : 6
    //         },
    //     ]
    // },
]
