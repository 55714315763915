import React from 'react';
import logo from '../../../assets/Logo/airplane.png'; // Adjust the path as needed

const Textwithimage = ({ text }) => {
  return (
    <span className="relative  inline-block mx-2 font-bold">
      <span className="relative z-0 bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] text-transparent bg-clip-text">
        {text}
      </span>
      <img
        src={logo}
        alt="Highlight"
        className="absolute top-0 left-60 w-[22%] h-full
         object-cover opacity-100 pointer-events-none z-0"
      />
    </span>
  );
};

export default Textwithimage;
