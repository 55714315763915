// Icons Import
import { FaArrowRight } from "react-icons/fa"
import { Link } from "react-router-dom"

// Image and Video Import
import Banner from "../assets/Images/banner.mp4"
// Component Imports
import Footer from "../components/Common/Footer"
import ReviewSlider from "../components/Common/ReviewSlider"
import CTAButton from "../components/core/HomePage/Button"
import CodeBlocks from "../components/core/HomePage/CodeBlocks"
import ExploreMore from "../components/core/HomePage/ExploreMore"
import HighlightText from "../components/core/HomePage/HighlightText"
import InstructorSection from "../components/core/HomePage/InstructorSection"
import LearningLanguageSection from "../components/core/HomePage/LearningLanguageSection"
import TimelineSection from "../components/core/HomePage/Timeline"
import Textwithimage from "../components/core/HomePage/Textwithimage"
import PDFViewer from "../components/core/HomePage/PDFViewer"
import ImageSlider from "../components/core/HomePage/ImageSlider"
import Flexbox from "../components/core/HomePage/Flexbox"
import StatsComponenet from "../components/core/AboutPage/Stats"
import StatsHome from "../components/core/HomePage/StatsHome"
import Features from "../components/core/HomePage/Features"
import Variouspdf from "../components/core/HomePage/Variouspdf"
// import samplePDF from '../assets/Images/freepdf.pdf';

function Home() {
  return (
    <div>


      <div className="relative mx-auto flex w-11/12 max-w-maxContent mt-11 flex-col items-center justify-between gap-2 text-white">
        {/* Become a Instructor Button */}
        {/* <Link to={"/signup"}>
          <div className="group mx-auto mt-2 mb-5 w-fit rounded-full bg-richblack-800 p-1 font-bold text-richblack-200 drop-shadow-[0_1.5px_rgba(255,255,255,0.25)] transition-all duration-200 hover:scale-95 hover:drop-shadow-none">
            <div className="flex flex-row items-center gap-2 rounded-full px-10 py-[5px] transition-all duration-200 group-hover:bg-richblack-900">
              <p>Excel Notes </p>
              <FaArrowRight />
            </div>
          </div>
        </Link> */}



        {/* Heading */}
        <div className="text-center text-blue-400  text-4xl mb-4 font-semibold">
          Empower Your Future with

          <Textwithimage text={"Excel Classes"} />
        </div>


        {/* Sub Heading */}
        <div className="-mt-3 w-[90%] text-center text-lg  text-richblack-300">
          With our online Excel courses, you can learn at your own pace, from
          anywhere in the world.
        </div>


        {/* CTA Buttons */}
        <div className="mt-8 flex flex-row gap-7">
          <CTAButton active={true} linkto={"/signup"}>
            Learn More
          </CTAButton>
          <CTAButton active={false} linkto={"/contact"}>
            Book a Demo
          </CTAButton>
        </div>

        {/* <Flexbox/> */}

        <div className="w-full mt-8 mb-10 ">
          <h1 className="text-4xl text-richblack-700 mt-4 items-center text-center justify-center mb-4">
            WHY CHOOSE <span className="text-black bg-yellow-25 px-2 rounded-lg">EXCEL CLASSES</span>
          </h1>

          <h2 className="text-2xl text-richblack-400 text-center mb-8">Explore Our Amazing Collection</h2>

          <Features />
        </div>



        {/* Video */}
        <div className="mx-3 my-7 shadow-[10px_-5px_50px_-5px] shadow-blue-200">
          <video
            className="shadow-[20px_20px_rgba(255,255,255)]"
            muted
            loop
            autoPlay
          >
            <source src={Banner} type="video/mp4" />
          </video>
        </div>

        {/* Code Section 1  */}
        <div>
          <CodeBlocks
            position={"lg:flex-row"}
            heading={
              <div className="text-4xl text-blue-400  font-semibold">
                Get Pdf Notes from top faculties by
                <HighlightText text={"Excel Classes."} />.
              </div>
            }
            subheading={
              "Our study notes are meticulously crafted to help you excel in your studies."
            }

            ctabtn1={{
              btnText: "Try it Yourself",
              link: "/signup",
              active: true,
            }}
            ctabtn2={{
              btnText: "Learn More",
              link: "/signup",
              active: false,
            }}

          />
        </div>





        {/* <Variouspdf /> */}

        <div className=" mb-8 " >
          <PDFViewer

          />
        </div>




        <ImageSlider />


        {/* Explore Section */}
        <ExploreMore />



      </div>

      {/* Section 2 */}


      <div className="bg-pure-greys-5 text-richblack-700">

        <div className="homepage_bg h-[320px]">
          {/* Explore Full Catagory Section */}
          <div className="mx-auto flex w-11/12 max-w-maxContent flex-col items-center justify-between gap-8">
            <div className="lg:h-[150px]"></div>
            <div className="flex flex-row gap-7 text-white lg:mt-8">
              <CTAButton active={true} linkto={"/signup"}>
                <div className="flex items-center gap-2">
                  Explore Full Catalog
                  <FaArrowRight />
                </div>
              </CTAButton>
              <CTAButton active={false} linkto={"/login"}>
                Learn More
              </CTAButton>
            </div>
          </div>
        </div>


        <div className="mx-auto flex w-11/12 max-w-maxContent flex-col items-center justify-between gap-8 ">
          {/* Job that is in Demand - Section 1 */}
          <div className="mb-10 mt-[-100px] flex flex-col justify-between gap-7 lg:mt-20 lg:flex-row lg:gap-0">
            <div className="text-4xl font-semibold lg:w-[45%] text-blue-400 ">
              Get the Scores you need for a{" "}
              <HighlightText text={"job that is in demand."} />
            </div>
            <div className="flex flex-col items-start gap-10 lg:w-[40%]">
              <div className="text-[16px]">
                The modern Excel Classes is the dictates its own terms. Today, to
                be a competitive specialist requires more than professional
                skills.
              </div>
              <CTAButton active={true} linkto={"/signup"}>
                <div className="">Learn More</div>
              </CTAButton>
            </div>
          </div>

          {/* Timeline Section - Section 2 */}
          <TimelineSection />










          {/* Learning Language Section - Section 3 */}
          <LearningLanguageSection />
          {/* Code Section 2 */}

        </div>
      </div>

      <div className="w-full ">
        <StatsHome />
      </div>

      {/* Section 3 */}
      <div className="relative mx-auto my-20 flex w-11/12 max-w-maxContent flex-col items-center justify-between gap-8 bg-[#f9f9f9] text-richblack-800">
        {/* Become a instructor section */}
        <InstructorSection />

        {/* Reviws from Other Learner */}
        <h1 className="text-center text-blue-400 text-4xl font-semibold mt-8">
          Reviews from other learners
        </h1>
        <ReviewSlider />
      </div>

      {/* Footer */}
      <Footer />
    </div>
  )
}

export default Home
