import React from 'react';
import { FaTrophy, FaUserGraduate, FaMoneyBillWave } from 'react-icons/fa';
import 'tailwindcss/tailwind.css';



function active(){
  alert('Registration Closed');
}

const ScholarshipInfo = () => {
  return (
    <div className="max-w-full mx-auto w-11/12 p-8 bg-white  rounded-lg">
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-blue-600 mt-10 animate-bounce">
          Excel Scholarship Admission Test
        </h1>
        <p className="mt-4 text-lg text-gray-700">
          Conducted by <span className="font-semibold">Excel Classes</span>
        </p>
      </div>
      <div className="text-gray-700">
        <p className="mb-6">
          Excel Classes is pleased to announce a scholarship exam for a 1-year coaching program. This exam will be held in offline mode and is designed to identify and reward talented students. Here are the details of the scholarship awards:
        </p>
        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Scholarship Awards</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="p-4   rounded-lg shadow-sm bg-richblack-5 text-center transform hover:scale-105 transition-transform duration-300">
              <FaTrophy className="text-4xl text-yellow-500 mx-auto mb-2 animate-bounce" />
              <h3 className="text-xl font-bold text-blue-500">1st Rank</h3>
              <p className="text-gray-800 mt-2">100% Scholarship</p>
              <p className="text-gray-600">(Full tuition fee waiver)</p>
            </div>
            <div className="p-4  rounded-lg shadow-sm bg-richblack-5 text-center transform hover:scale-105 transition-transform duration-300">
              <FaUserGraduate className="text-4xl text-gray-500 mx-auto mb-2 animate-bounce" />
              <h3 className="text-xl font-bold text-blue-500">2nd Rank</h3>
              <p className="text-gray-800 mt-2">75% Scholarship</p>
              <p className="text-gray-600">(Three-quarters tuition fee waiver)</p>
            </div>
            <div className="p-4  rounded-lg shadow-sm bg-richblack-5 text-center transform hover:scale-105 transition-transform duration-300">
              <FaMoneyBillWave className="text-4xl text-green-500 mx-auto mb-2 animate-bounce" />
              <h3 className="text-xl font-bold text-blue-500">3rd Rank</h3>
              <p className="text-gray-800 mt-2">50% Scholarship</p>
              <p className="text-gray-600">(Half tuition fee waiver)</p>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Participation Details</h2>
          <p>
            This exam is expected to see the participation of over <span className="font-semibold">500 students</span>. The application process is conducted online, with a nominal participation fee of <span className="font-semibold">₹100</span>.
          </p>
        </div>
        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">How to Register</h2>
          <p>
            To participate in the exam, please fill out the online registration form available on our website. Ensure that all details are correctly filled in, and the registration fee is paid.
          </p>
        </div>
      </div>
      <div className="text-center mt-10">
        <a
          href="#" onClick={active}
          className="inline-block bg-blue-500 text-white text-lg font-semibold px-8 py-3 rounded-lg hover:bg-blue-600 transition duration-200 transform hover:translate-y-1"
        >
          Register Now
        </a>
      </div>
    </div>
  );
};

export default ScholarshipInfo;
