import React, { useState, useEffect } from 'react';
import 'tailwindcss/tailwind.css';

const laptopSlides = [
    {
      eachSlide: 'url(https://i.ibb.co/nPLfMTV/1.jpg)',
    },
    {
      eachSlide: 'url(https://i.ibb.co/bF7B909/2.jpg)',
    },
    {
      eachSlide: 'url(https://i.ibb.co/W0Z6kQv/3.jpg)',
    },
];
const mobileSlides = [
    {
      eachSlide: 'url(https://i.ibb.co/pP9ZXmG/mobile-333333.jpg)',
    },
    {
      eachSlide: 'url(https://i.ibb.co/nbZbRyW/mobile-5656858.jpg)',
    },
    {
      eachSlide: 'url(https://i.ibb.co/W0Z6kQv/3.jpg)',
    },
];

const PDFViewer = () => {
    const [active, setActive] = useState(0);
    const [autoplay, setAutoplay] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const max = isMobile ? mobileSlides.length : laptopSlides.length;

    const intervalBetweenSlides = () => autoplay && setActive(active === max - 1 ? 0 : active + 1);

    useEffect(() => {
        const interval = setInterval(() => intervalBetweenSlides(), 3000);
        return () => clearInterval(interval);
    }, [active, autoplay]);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleAutoPlay = () => setAutoplay(autoplay);

    const nextOne = () => active < max - 1 && setActive(active + 1);

    const prevOne = () => active > 0 && setActive(active - 1);

    const isActive = value => active === value && 'active';

    const setSliderStyles = () => {
        const transition = active * -100;
        const slides = isMobile ? mobileSlides : laptopSlides;
        return {
            width: (slides.length * 100) + 'vw',
            transform: 'translateX(' + transition + 'vw)'
        };
    };

    const renderSlides = () => {
        const slides = isMobile ? mobileSlides : laptopSlides;
        return slides.map((item, index) => (
            <div 
                className='w-screen object-cover h-auto flex justify-center items-center bg-cover bg-center' 
                key={index} 
                style={{ backgroundImage: item.eachSlide, height: '200px' }}>
            </div>
        ));
    };

    const renderDots = () => {
        const slides = isMobile ? mobileSlides : laptopSlides;
        return slides.map((slide, index) => (
            <li 
                className={`${isActive(index)} inline-block p-1`}   
                key={index}>
                    <button onClick={() => setActive(index)} className="text-white hover:opacity-70 focus:outline-none">
                        <span>&#9679;</span>
                    </button>
            </li> 
        ));
    };

    const renderPlayStop = () => !autoplay
        ? (
            <svg fill='#FFFFFF' height='24' viewBox='0 0 24 24' width='24'>
                <path d='M0 0h24v24H0z' fill='none'/>
                <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 14H9V8h2v8zm4 0h-2V8h2v8z'/>
            </svg> 
        )
        : (
            <svg fill='#FFFFFF' height='24' viewBox='0 0 24 24' width='24'>
                <path d='M0 0h24v24H0z' fill='none'/>
                <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14.5v-9l6 4.5-6 4.5z'/>
            </svg>
        );

    const renderArrows = () => (
        <>
            <button 
                type='button'
                className='absolute top-1/2 left-0 bg-none h-15 border-0 cursor-pointer transition ease-linear duration-300 outline-none transform -translate-y-1/2 hover:opacity-70 hover:-translate-x-2' 
                onClick={() => prevOne()} >
                <svg fill='#FFFFFF' width='50' height='50' viewBox='0 0 24 24'>
                    <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'/>
                    <path d='M0 0h24v24H0z' fill='none'/>
                </svg>
            </button>
            <button 
                type='button'
                className='absolute top-1/2 right-0 bg-none h-15 border-0 cursor-pointer transition ease-linear duration-300 outline-none transform -translate-y-1/2 hover:opacity-70 hover:translate-x-2' 
                onClick={() => nextOne()} > 
                <svg fill='#FFFFFF' height='50' viewBox='0 0 24 24' width='50'>
                    <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'/>
                    <path d='M0 0h24v24H0z' fill='none'/>
                </svg>
            </button>
        </>
    );

    return (
        <section className='w-screen object-cover  h-full overflow-hidden relative'>
            <div 
                className='h-fit transition-all duration-500 flex' 
                style={setSliderStyles()}>
                {renderSlides()}
            </div>
            {renderArrows()}
         
            <button 
                type='button'
                className='absolute bottom-2 right-5 text-gray-700 z-50 focus:outline-none hover:opacity-70' 
                onClick={toggleAutoPlay}> 
                {renderPlayStop()}
            </button>
            <ul className='absolute bottom-2 left-1/2 transform -translate-x-1/2 z-0 flex space-x-2'>
                {renderDots()}
            </ul>
        </section>
    );
};

export default PDFViewer;
