import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "../../App.css";
import { FaStar } from "react-icons/fa";
import { Autoplay, FreeMode, Pagination } from "swiper";
import { apiConnector } from "../../services/apiConnector";
import { ratingsEndpoints } from "../../services/apis";

function ReviewSlider() {
  const [reviews, setReviews] = useState([]);
  const truncateWords = 15;

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const { data } = await apiConnector("GET", ratingsEndpoints.REVIEWS_DETAILS_API);
        if (data?.success) {
          setReviews(data?.data);
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchReviews();
  }, []);

  return (
    <div className="text-white">
      <div className="max-w-screen-lg w-[93%]  mx-auto py-12">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[FreeMode, Pagination, Autoplay]}
          className="relative"
        >
          {reviews.map((review, index) => (
            <SwiperSlide key={index} className="swiper-slide">
              <div className="bg-richblack-800 rounded-lg border border-gray-800 p-6 shadow-lg">
                <div className="flex items-center mb-4">
                  <img
                    src={review?.user?.image || `https://api.dicebear.com/5.x/initials/svg?seed=${review?.user?.firstName}%20${review?.user?.lastName}`}
                    alt={`${review?.user?.firstName} ${review?.user?.lastName}`}
                    className="h-12 w-12 rounded-full object-cover"
                  />
                  <div className="ml-4">
                    <h2 className="text-lg font-semibold text-gray-100">
                      {`${review?.user?.firstName} ${review?.user?.lastName}`}
                    </h2>
                    <p className="text-sm text-gray-400">{review?.course?.courseName}</p>
                  </div>
                </div>
                <p className="text-sm text-gray-300 mb-6">
                  {review?.review.split(" ").slice(0, truncateWords).join(" ")}
                  {review?.review.split(" ").length > truncateWords && " ..."}
                </p>
                <div className="flex items-center">
                  <ReactStars
                    count={5}
                    value={review.rating}
                    size={24}
                    edit={false}
                    activeColor="#ffd700"
                    emptyIcon={<FaStar className="text-gray-400" />}
                    fullIcon={<FaStar className="text-yellow-400" />}
                  />
                  <p className="ml-2 text-lg font-semibold text-yellow-400">{review.rating.toFixed(1)}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default ReviewSlider;
