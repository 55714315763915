export const FooterLink2 = [
  {
    title: "Subjects",
    links: [
      { title: "Maths", link: "/Maths" },
      { title: "Chemistry", link: "/Chemistry" },
      { title: "English", link: "/English" },
      { title: "Biology", link: "/Biology" },
      { title: "Physics", link: "/Physics" },
    ],
  },
  {
    title: "Languages",
    links: [
      { title: "Hindi", link: "/Hindi" },
      { title: "Hinglish", link: "/Hinglish" },

    ],
  },
  {
    title: "Career building",
    links: [
        {title: "Career paths", link: "/career-paths"},
        {title: "Career services", link: "/career-services"},
        {title: "-", link: "/hi"},
        {title: "Full Catalog", link: "/full-catalog"},
    
    ]
  }
];
